<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<template>
  <login
    logo="/img/login.svg"
    image="/img/login-background.jpg"
  >
    <template v-slot:login-actions>
      <div id="iiotActivateProfile">
        <v-form
          id="iiotActivateProfileLeftPart"
          ref="activateProfile"
          @keyup.enter.native="changePass"
        >
          <div class="title mb-10">
            <h1>{{ $t('login.activateProfile.title') }}</h1>
            <v-divider class="mt-2" />
          </div>
          <v-row class="justify-center mt-4">
            <v-text-field
              id="iiotActivateProfileNewPassword"
              v-model="newPass"
              :label="$t('login.activateProfile.newPassField')"
              :required="true"
              type="password"
              :rules="[activateRules.required, activateRules.newPassword,
              activateRules.passwordLength]"
              class="default-input"
              validate-on-blur
            />
          </v-row>
          <v-row class="justify-center">
            <v-text-field
              id="iiotActivateProfileConfirmPassword"
              v-model="confirmPass"
              :label="$t('login.activateProfile.confirmPassField')"
              :required="true"
              type="password"
              :rules="[activateRules.required, activateRules.compare, activateRules.passwordLength]"
              class="default-input"
              validate-on-blur
            />
          </v-row>
          <v-col
            lg="7"
            md="7"
            sm="4"
            offset-md="2"
            offset-sm="4"
          >
            <v-row class="justify-center mt-16">
              <nerve-button
                id="iiotActivateProfileSaveButton"
                :disabled="alreadyClicked || !validateInput"
                :text="$t('login.activateProfile.saveBtn')"
                type-of-btn="action"
                size="normal"
                @click-event="changePass()"
              />
            </v-row>
          </v-col>
        </v-form>
      </div>
    </template>
  </login>
</template>
<script>
import { Login, NerveButton } from 'nerve-ui-components';
import { VALIDATION_REGEX, ACTIVATE_PROFILE } from '@/constants';
import Logger from '@/utils/logger';

export default {
  components: { Login, NerveButton },
  data() {
    return {
      newPass: '',
      confirmPass: '',
      activationToken: '',
      alreadyClicked: false,
      hidePassword: true,
      inputValue: this.value,
    };
  },
  computed: {
    validateInput() {
      return (this.newPass !== ''
        && this.confirmPass !== ''
        && VALIDATION_REGEX.NEW_PASSWORD.test(this.newPass)
        && (this.newPass === this.confirmPass));
    },
    activateRules() {
      return {
        required: (v) => !!v || this.$t('login.activateProfile.requiredRule'),
        newPassword: (v) => VALIDATION_REGEX.NEW_PASSWORD.test(v) || this.$t('login.activateProfile.patternRule'),
        compare: (v) => (this.newPass === v) || this.$t('login.activateProfile.compareRule'),
        passwordLength: (v) => v.length >= ACTIVATE_PROFILE.MIN_LENGTH || this.$t('baseForm.passwordMinLengthMsg', { msgLength: ACTIVATE_PROFILE.MIN_LENGTH }),
      };
    },
  },
  async created() {
    try {
      if (localStorage.getItem('session')) {
        localStorage.removeItem('userDetails');
        localStorage.removeItem('session');
      }
      // eslint-disable-next-line prefer-destructuring
      this.activationToken = window.location.pathname.split('/activate/')[1];
      if (!this.activationToken) {
        setTimeout(() => {
          this.$router.push({ name: 'Login' });
        }, 1200);
      }
      await this.$store.dispatch('activate-profile/isActivated', { activationToken: this.activationToken });
      const isActivated = this.$store.getters['activate-profile/isActivated'];
      if (!isActivated) {
        await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: this.$t('login.activateProfile.profileAlreadyExist'),
          color: 'red',
          showClose: true,
        });
        setTimeout(() => {
          this.$router.push({ name: 'Login' });
        }, 1200);
        return;
      }
      await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: this.$t('login.activateProfile.setNewPassMsg'),
        color: 'success',
        showClose: true,
      });
    } catch (e) {
      Logger.error(e);
    }
  },
  methods: {
    async changePass() {
      this.alreadyClicked = true;
      try {
        if (!this.$refs.activateProfile.validate()) {
          return;
        }
        await this.$store.dispatch('activate-profile/profileActivation', { activationToken: this.activationToken, secret: this.newPass, confirmation: this.confirmPass });
        await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: this.$t('login.activateProfile.passwordIsChanged'),
          color: 'success',
          showClose: true,
        });
        setTimeout(() => {
          this.$router.push({ name: 'Login' });
        }, 1200);
      } catch (e) {
        Logger.error(e);
        this.alreadyClicked = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
    #iiotActivateProfileLeftPart {
      padding: 70px;
      .title {
        > h1 {
            text-align: center;
        }
        > .v-divider {
            margin-left: 50%;
        }
      }
    }
</style>
